var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen" },
    [
      _c(
        "div",
        { staticClass: "container mx-auto" },
        [
          _c("div", { staticClass: "w-full grid grid-cols-3" }, [
            _c("div", { staticClass: "col-span-2" }, [
              _vm.quote
                ? _c(
                    "h3",
                    { staticClass: "mt-3" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.commons.quotes.estimate")) +
                          " "
                      ),
                      !_vm.isSet
                        ? [
                            _vm._v(
                              " - " +
                                _vm._s(
                                  _vm.$t(
                                    "pages.commons.quotes." +
                                      _vm.quoteState(
                                        _vm.quote,
                                        _vm.loginUserRole
                                      )
                                  )
                                )
                            ),
                          ]
                        : _vm._e(),
                      _c("img", {
                        staticClass: "w-8 h-8 mx-2 inline",
                        attrs: {
                          src: require("@/assets/image/negotiating_icon.svg"),
                        },
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm.quote && _vm.quote.created_at
              ? _c(
                  "div",
                  { staticClass: "text-right text-sm col-span-1 mt-3" },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("pages.commons.quotes.request_at")) +
                          " " +
                          _vm._s(_vm._f("date")(_vm.quote.created_at))
                      ),
                    ]),
                    _vm.showProviderMessage
                      ? [
                          _vm.quote.quote_provider &&
                          _vm.quote.quote_provider.status > 2
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.commons.quotes.delivery_by")
                                    ) +
                                    _vm._s(
                                      _vm._f("date")(_vm.quote.delivery_at)
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pages.commons.quotes.must_respond_by"
                                    )
                                  ) +
                                    _vm._s(
                                      _vm._f("date")(_vm.quote.estimate_at)
                                    )
                                ),
                              ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm.quote && _vm.quote.uuid
            ? _c("div", { staticClass: "w-full text-sm mb-6" }, [
                _c(
                  "p",
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("pages.commons.quotes.id")) + ": "
                    ),
                    _c("FlCopyableText", [_vm._v(_vm._s(_vm.quote.uuid))]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isImpersonated && _vm.impersonatedRole === "provider"
            ? _c(
                "div",
                { staticClass: "w-full h-10 text-right" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-block px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold",
                      on: {
                        click: function ($event) {
                          return _vm.impersonatedLogout(
                            "/admin/quotes/" + _vm.quote.uuid
                          )
                        },
                      },
                    },
                    [_vm._v("運用でquoteの詳細に戻る")]
                  ),
                  _vm.quote && _vm.quote.status > 2
                    ? [
                        !_vm.isEditing
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold mb-4",
                                on: {
                                  click: function ($event) {
                                    _vm.isEditing = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.administrators.index.edit")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-very-light-grey font-bold mb-4",
                                  on: {
                                    click: function ($event) {
                                      _vm.isEditing = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("pages.commons.quotes.cancel")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold mb-4",
                                  on: {
                                    click: function ($event) {
                                      _vm.updateQuoteMovies(false)
                                      _vm.isEditing = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("pages.commons.quotes.update")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.showProviderMessage && _vm.quote && _vm.quote.status < 3
            ? [
                _c("div", { staticClass: "w-full mb-4" }, [
                  _c("p", [
                    _vm._v(
                      "バイヤーよりの許諾の見積もり依頼が参っております。"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " バイヤーの要望は、各モジュールの上部に記載されておりますので、御社には、この要望をご確認の上、各モジュール内で回答をいただき、最後に、「この内容で見積書を提出」ボタンを押していただければ、見積書の作成は完了です。 "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "バイヤーが購入された場合には、メールでお知らせいたしますので、しばらくお待ちください。"
                    ),
                  ]),
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v(
                      " 素材作成などでお困りの場合には、有償サービスのご利用もご検討ください。"
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "underline underline-offset-1",
                        attrs: {
                          href: "https://filmination.jp/ja/service-list/index.html",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v("コチラ "),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "なお、見積もり回答は、必ず「支払方法(金額)」の項目から始めてください。"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " また、下記、見積もり内に、複数の作品がある場合には、全ての作品に対して回答を終えないと、「この内容で見積書を提出」ボタンを押すことができません。 "
                    ),
                  ]),
                  _c("p", [
                    _vm._v("一時保存機能はございませんので、ご注意ください。"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "一部の作品のみ、見積書を発行されたい場合には、お問い合わせよりご連絡をお願いいたします。"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "なんらかの事情で、見積書作成が不可能な場合には、見積書作成締切日の" +
                        _vm._s(_vm._f("date")(_vm.quote.estimate_at)) +
                        "をすぎるまで放置ください。"
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm.quote && _vm.quoteMovies
            ? _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("pages.commons.quotes.ch_client_name")) +
                      " : " +
                      _vm._s(_vm.quote.consumer.company_name) +
                      " "
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "font-bold py-2 px-4 action_btn ml-2 inline-block cursor-pointer",
                      attrs: {
                        href: "/ch/vod_profile/" + this.quote.consumer.uuid,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("pages.commons.quotes.ch_preview_guide")
                          ) +
                          " "
                      ),
                      _c(
                        "svg",
                        {
                          staticClass: "w-5 h-5 inline -mt-1",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "1.5",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "float-left w-9/12 mb-4 font-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("pages.commons.quotes.download_agreement")) +
                " "
            ),
            _c(
              "button",
              {
                staticClass: "font-bold py-2 px-4 action_btn ml-2",
                on: {
                  click: function ($event) {
                    return _vm.downloadIndividualLicenseAgreement()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("commons.fields.download")) + " ")]
            ),
          ]),
          _vm.quote && _vm.quoteMovies && _vm.showProviderMessage
            ? _c("div", { staticClass: "float-right" }, [
                _c(
                  "h4",
                  { staticClass: "pb-1" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.commons.quotes.payment_to_customer")
                        ) +
                        " " +
                        _vm._s(
                          _vm._f("price")(
                            _vm.customerTotalPrice(
                              _vm.quoteMovies,
                              _vm.showInYen(
                                _vm.quote,
                                (_vm.quotesSingleMax(_vm.quote) +
                                  _vm.quotesSingleMin(_vm.quote)) /
                                  2
                              )
                            ) + _vm.adjustmentTotal(_vm.quote, _vm.quoteMovies),
                            "JPY"
                          )
                        ) +
                        " (" +
                        _vm._s(_vm.contractMonths(_vm.quote)) +
                        "ヶ月) "
                    ),
                    _vm.hasMGs(_vm.quote, _vm.quoteMovies, "provider")
                      ? [
                          _vm._v(
                            " (" +
                              _vm._s(_vm.$t("pages.commons.quotes.mg")) +
                              _vm._s(
                                _vm._f("price")(
                                  _vm.providerTotalMG(
                                    _vm.quote,
                                    _vm.quoteMovies
                                  ),
                                  "JPY"
                                )
                              ) +
                              ") "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.quote.pricing_type === 2 && _vm.showProviderMessage
                  ? _c("span", { staticClass: "text-xs" }, [
                      _vm._v(
                        "想定額は最低金額～最高金額の平均値×期間で算出された数字です"
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "clear-both" }, [
            _vm.quote && _vm.quote.contract_terms
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-full p-2 bg-dark-grey rounded mb-4 grid grid-cols-2 gap-2",
                  },
                  [
                    _c("div", { staticClass: "col-span-2" }, [
                      _c("span", { staticClass: "font-bold text-xs" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pages.commons.quotes.message_contract")
                          )
                        ),
                      ]),
                    ]),
                    _vm.translatedContractTerms &&
                    _vm.translatedContractTerms !== _vm.quote.contract_terms &&
                    _vm.showProviderMessage
                      ? [
                          _c("span", { staticClass: "col-span-1" }, [
                            _vm._v(_vm._s(_vm.quote.contract_terms)),
                          ]),
                          _c("span", { staticClass: "col-span-1" }, [
                            _vm._v(_vm._s(_vm.translatedContractTerms)),
                          ]),
                        ]
                      : _c("span", { staticClass: "col-span-2" }, [
                          _vm._v(_vm._s(_vm.quote.contract_terms)),
                        ]),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "clear-both" },
            _vm._l(_vm.quoteMovies, function (qm, quoteIndex) {
              return _c(
                "div",
                { key: "quote-movie-" + quoteIndex, staticClass: "mb-2" },
                [
                  _c(
                    "QuoteMovieViewItem",
                    {
                      attrs: {
                        title: _vm.isSet
                          ? _vm.quote.set_name
                          : _vm.getQMTitleWithState(qm, _vm.loginUserRole),
                        isOK:
                          _vm.filledQuoteMovies[quoteIndex] ||
                          _vm.finishUpdateQuoteMovie,
                        bottomTitle:
                          _vm.loginUserRole === "provider"
                            ? _vm.individualPrice(qm)
                            : "",
                        hidesTrash: !(
                          _vm.loginUserRole === "consumer" && qm.status < 4
                        ),
                        disabledIcon: !_vm.filledQuoteMovies[quoteIndex],
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.planDelete(quoteIndex)
                        },
                      },
                    },
                    [
                      _vm.quote.pricing_type === 1
                        ? _c(
                            "TermsAccordion",
                            {
                              ref: "termsAccordion-" + quoteIndex + "-price-1",
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) +
                                  _vm.$t("commons.fields.payment_type") +
                                  " : " +
                                  _vm.$t("pages.commons.quotes.flat_rate"),
                              },
                            },
                            [
                              _vm.showProviderMessage
                                ? _c("p", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      " この金額で承諾される場合には、御社解答欄の承諾ボタンを押してください。 "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "TermsItem",
                                {
                                  attrs: {
                                    showCheckbox: false,
                                    showRightSide: _vm.showProviderResponse(qm),
                                    isNegotiating: _vm.isNegotiating(qm),
                                  },
                                },
                                [
                                  _c("TermsField", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "original",
                                          fn: function () {
                                            return [
                                              _c("p", { staticClass: "mb-1" }, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.consumer_payment_fixed",
                                                        [
                                                          _vm.loginUserRole ===
                                                          "provider"
                                                            ? _vm.consumerFixedPriceYenString(
                                                                _vm.quote,
                                                                qm,
                                                                true
                                                              )
                                                            : _vm.consumerFixedPriceString(
                                                                _vm.quote,
                                                                qm,
                                                                true
                                                              ),
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _vm.showProviderMessage
                                                ? [
                                                    _c("p", [
                                                      _vm._v(
                                                        " その内、御社への入金は、" +
                                                          _vm._s(
                                                            _vm.providerFixedPriceString(
                                                              _vm.quote,
                                                              qm,
                                                              true
                                                            )
                                                          ) +
                                                          "（税別、販売手数料別） "
                                                      ),
                                                    ]),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-xs mb-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " ※販売手数料は、" +
                                                            _vm._s(
                                                              _vm.filminationFixedPriceString(
                                                                _vm.quote,
                                                                qm,
                                                                true
                                                              )
                                                            ) +
                                                            "となります。 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "negotiate",
                                          fn: function () {
                                            return [
                                              qm.flat_price === null
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "w-full h-full flex justify-center items-center",
                                                      },
                                                      [_vm._v("未解答")]
                                                    ),
                                                  ]
                                                : _vm.quote.flat_price ===
                                                  _vm.quoteMovies[quoteIndex]
                                                    .flat_price
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "w-full h-full flex justify-center items-center",
                                                      },
                                                      [_vm._v("合意")]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          " 購入者は、今回の条件で、" +
                                                            _vm._s(
                                                              _vm.consumerFixedPriceYenString(
                                                                _vm.quote,
                                                                _vm.quoteMovies[
                                                                  quoteIndex
                                                                ]
                                                              )
                                                            ) +
                                                            "を支払います。"
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " その内、御社への入金は、" +
                                                            _vm._s(
                                                              _vm.providerFixedPriceString(
                                                                _vm.quote,
                                                                _vm.quoteMovies[
                                                                  quoteIndex
                                                                ]
                                                              )
                                                            ) +
                                                            "（税別、販売手数料別） "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-xs mb-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " ※販売手数料は、、" +
                                                            _vm._s(
                                                              _vm.filminationFixedPriceString(
                                                                _vm.quote,
                                                                _vm.quoteMovies[
                                                                  quoteIndex
                                                                ]
                                                              )
                                                            ) +
                                                            "となります。 "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                              _c("ValidateParts", {
                                                attrs: {
                                                  isDisplayErrors: false,
                                                  rules: {
                                                    required: true,
                                                    is: _vm.quote.flat_price,
                                                  },
                                                  immediate: true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.quoteMovies[quoteIndex]
                                                      .flat_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quoteMovies[
                                                        quoteIndex
                                                      ],
                                                      "flat_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quoteMovies[quoteIndex].flat_price",
                                                },
                                              }),
                                              _c(
                                                "NoticeLinkModal",
                                                {
                                                  attrs: {
                                                    modalName:
                                                      "fixedPriceModal" +
                                                      quoteIndex,
                                                  },
                                                  on: {
                                                    "before-open": function (
                                                      $event
                                                    ) {
                                                      _vm.priceBeforeDirty =
                                                        _vm.quoteMovies[
                                                          quoteIndex
                                                        ].flat_price
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "契約金額を変更し、カウンターオファーを希望される場合には、以下に、販売価格を入力ください。"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-red-600 text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ※金額を変更される場合、本件は成立の可能性が、低くなりますのでご留意ください。 "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("ActivePrice", {
                                                    attrs: {
                                                      original:
                                                        _vm.priceBeforeDirty,
                                                      quote: _vm.quote,
                                                      quoteMovie:
                                                        _vm.quoteMovies[
                                                          quoteIndex
                                                        ],
                                                      modalName:
                                                        "fixedPriceModal" +
                                                        quoteIndex,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.checkCanSubmit(
                                                          qm
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.quoteMovies[
                                                          quoteIndex
                                                        ].flat_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.quoteMovies[
                                                            quoteIndex
                                                          ],
                                                          "flat_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "quoteMovies[quoteIndex].flat_price",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "result",
                                          fn: function () {
                                            return [
                                              _vm.quote.flat_price ===
                                              qm.flat_price
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.agree_to_price"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.price_counter_offer",
                                                          [
                                                            _vm.localeCurrencyString(
                                                              _vm.quote,
                                                              qm.flat_price,
                                                              _vm.loginUserRole
                                                            ),
                                                          ]
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm.isNegotiating(qm)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 w-full flex justify-end",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow disabled:bg-very-light-grey font-bold",
                                          attrs: {
                                            disabled:
                                              _vm.quoteMovies[quoteIndex]
                                                .flat_price ===
                                              _vm.quote.flat_price,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.$set(
                                                _vm.quoteMovies[quoteIndex],
                                                "flat_price",
                                                _vm.quote.flat_price
                                              )
                                              _vm.$nextTick(function () {
                                                return _vm.checkCanSubmit()
                                              })
                                            },
                                          },
                                        },
                                        [_vm._v(" 承認 ")]
                                      ),
                                      _vm.quoteMovies[quoteIndex].flat_price !==
                                      null
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$modal.show(
                                                    "fixedPriceModal" +
                                                      quoteIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 変更 ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.quote.pricing_type === 2
                        ? _c(
                            "TermsAccordion",
                            {
                              ref: "termsAccordion-" + quoteIndex + "-price-2",
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) +
                                  _vm.$t("commons.fields.payment_type") +
                                  " : " +
                                  _vm.$t("pages.commons.quotes.revenue_share"),
                              },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-6" },
                                [
                                  _vm.loginUserRole === "provider"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.commons.quotes.revenue_share_detail_percentage",
                                                [
                                                  (_vm.quote.revenue_percent *
                                                    (100 - qm.fee_rate)) /
                                                    100,
                                                  100 -
                                                    _vm.quote.revenue_percent,
                                                  (qm.fee_rate *
                                                    _vm.quote.revenue_percent) /
                                                    100,
                                                ]
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.commons.quotes.revenue_share_detail_simple",
                                                [_vm.quote.revenue_percent]
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm.showProviderMessage
                                ? [
                                    _c("p", { staticClass: "mb-1" }, [
                                      _vm._v(
                                        " 契約期間中の配信会社様の想定売り上げは、" +
                                          _vm._s(
                                            _vm.consumerPlannedPriceString(
                                              _vm.quote,
                                              qm
                                            )
                                          ) +
                                          "です。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " この内、御社にお支払いする想定支払い額は、" +
                                          _vm._s(
                                            _vm.providerPlannedPriceString(
                                              _vm.quote,
                                              qm
                                            )
                                          ) +
                                          "（税別、販売手数料別）となります。 "
                                      ),
                                    ]),
                                    _c("p", { staticClass: "text-xs" }, [
                                      _vm._v(
                                        " ※上記「想定売り上げ」に対する販売手数料は、" +
                                          _vm._s(
                                            _vm.filminationPlannedPriceString(
                                              _vm.quote,
                                              qm
                                            )
                                          ) +
                                          "になります。 "
                                      ),
                                    ]),
                                    _c("p", { staticClass: "text-xs" }, [
                                      _vm._v(
                                        "※レベニューシェアご報告時期：12月締め、翌々月のご報告。"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "text-xs" }, [
                                      _vm._v(
                                        "ただし、ご報告金額が5,000円に満たない場合には契約終了時に一括してご報告。"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "text-xs" }, [
                                      _vm._v(
                                        "なお、RS売り上げに関しては毎年最低5,000円/作品を、FIの処理手数料として優先的に確保させていただきます。"
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "text-xs text-red-600" },
                                      [
                                        _vm._v(
                                          " ※想定売り上げや、想定支払い額は、あくまで想定の金額で保証されるものではありません。 "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "text-xs mb-2" }, [
                                      _vm._v(
                                        " ※お支払いに関する注意事項：RS報告の際の許諾料が5万円（消費税および地方消費税別）に満たない場合には、次回へ繰り越します。 "
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _c(
                                "TermsItem",
                                {
                                  attrs: {
                                    showCheckbox: false,
                                    showRightSide: _vm.showProviderResponse(qm),
                                    isNegotiating: _vm.isNegotiating(qm),
                                  },
                                },
                                [
                                  _c("TermsField", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "original",
                                          fn: function () {
                                            return [
                                              _c("p", {
                                                staticClass: "mb-1",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t(
                                                      "pages.commons.quotes.consumer_mg_proposal",
                                                      [
                                                        _vm.loginUserRole ===
                                                        "provider"
                                                          ? _vm.consumerMGPriceYenString(
                                                              _vm.quote,
                                                              qm,
                                                              true
                                                            )
                                                          : _vm.consumerMGPriceString(
                                                              _vm.quote,
                                                              qm,
                                                              true
                                                            ),
                                                      ]
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm.showProviderMessage
                                                ? [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          " 今回の、御社への支払い額は、" +
                                                            _vm._s(
                                                              _vm.providerMGPriceString(
                                                                _vm.quote,
                                                                qm,
                                                                true
                                                              )
                                                            ) +
                                                            "（税別、販売手数料別）となります。 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "text-xs",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " ※販売手数料は、"
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              "text-red-600":
                                                                _vm.filminationMGPrice(
                                                                  _vm.quote,
                                                                  qm,
                                                                  true
                                                                ) < 0,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.filminationMGPriceString(
                                                                  _vm.quote,
                                                                  qm,
                                                                  true
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v("になります。 "),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "negotiate",
                                          fn: function () {
                                            return [
                                              qm.price_minimum_guarantee ===
                                              null
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "w-full h-full flex justify-center items-center",
                                                      },
                                                      [_vm._v("未解答")]
                                                    ),
                                                  ]
                                                : _vm.quote
                                                    .price_minimum_guarantee ===
                                                  qm.price_minimum_guarantee
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "w-full h-full flex justify-center items-center",
                                                      },
                                                      [_vm._v("合意")]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "font-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "購入者様が支払う最低保証金額"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "は、" +
                                                            _vm._s(
                                                              _vm.consumerMGPriceYenString(
                                                                _vm.quote,
                                                                qm
                                                              )
                                                            ) +
                                                            "です。 "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.showProviderMessage
                                                      ? _c("p", [
                                                          _vm._v(
                                                            " 今回の、御社への支払い額は、" +
                                                              _vm._s(
                                                                _vm.providerMGPriceString(
                                                                  _vm.quote,
                                                                  qm
                                                                )
                                                              ) +
                                                              "（税別、販売手数料別）となります。 "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "text-xs",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " ※販売手数料は、"
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              "text-red-600":
                                                                _vm.filminationMGPrice(
                                                                  _vm.quote,
                                                                  qm
                                                                ) < 0,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.filminationMGPriceString(
                                                                  _vm.quote,
                                                                  qm
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v("になります。 "),
                                                      ]
                                                    ),
                                                  ],
                                              _c("ValidateParts", {
                                                attrs: {
                                                  isDisplayErrors: false,
                                                  rules: {
                                                    required: true,
                                                    is: _vm.quote
                                                      .price_minimum_guarantee,
                                                  },
                                                  immediate: true,
                                                },
                                                model: {
                                                  value:
                                                    _vm.quoteMovies[quoteIndex]
                                                      .price_minimum_guarantee,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quoteMovies[
                                                        quoteIndex
                                                      ],
                                                      "price_minimum_guarantee",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quoteMovies[quoteIndex].price_minimum_guarantee",
                                                },
                                              }),
                                              _c(
                                                "NoticeLinkModal",
                                                {
                                                  attrs: {
                                                    modalName:
                                                      "mgPriceModal" +
                                                      quoteIndex,
                                                  },
                                                  on: {
                                                    "before-open": function (
                                                      $event
                                                    ) {
                                                      _vm.priceBeforeDirty =
                                                        qm.price_minimum_guarantee
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "契約金額を変更し、カウンターオファーを希望される場合には、以下に、入力ください。"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-red-600 text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " ※金額を変更される場合、本件は成立の可能性が、低くなりますのでご留意ください。 "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("ActivePrice", {
                                                    attrs: {
                                                      original:
                                                        _vm.priceBeforeDirty,
                                                      quote: _vm.quote,
                                                      quoteMovie: qm,
                                                      modalName:
                                                        "mgPriceModal" +
                                                        quoteIndex,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.checkCanSubmit(
                                                          qm
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.quoteMovies[
                                                          quoteIndex
                                                        ]
                                                          .price_minimum_guarantee,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.quoteMovies[
                                                            quoteIndex
                                                          ],
                                                          "price_minimum_guarantee",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "quoteMovies[quoteIndex].price_minimum_guarantee",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "result",
                                          fn: function () {
                                            return [
                                              _vm.quote
                                                .price_minimum_guarantee ===
                                              qm.price_minimum_guarantee
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.agree_to_price"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.price_counter_offer",
                                                          [
                                                            _vm.loginUserRole ===
                                                            "provider"
                                                              ? _vm.providerMGPriceString(
                                                                  _vm.quote,
                                                                  qm,
                                                                  false
                                                                )
                                                              : _vm.consumerMGPriceString(
                                                                  _vm.quote,
                                                                  qm,
                                                                  false
                                                                ),
                                                          ]
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm.showProviderMessage
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-xs text-red-600 mt-2",
                                      },
                                      [
                                        _vm._v(
                                          " ※支払い時に、最低保証金額と、売り上げの％で、より大きな金額の方が採用されます。 "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "mb-1" }, [
                                      _vm._v(
                                        " この金額で承諾される場合には、承諾ボタンを、拒否される場合には拒否ボタンを押してください。 "
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.isNegotiating(qm)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 w-full flex justify-end",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow disabled:bg-very-light-grey font-bold",
                                          attrs: {
                                            disabled:
                                              _vm.quoteMovies[quoteIndex]
                                                .price_minimum_guarantee ===
                                              _vm.quote.price_minimum_guarantee,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.$set(
                                                _vm.quoteMovies[quoteIndex],
                                                "price_minimum_guarantee",
                                                _vm.quote
                                                  .price_minimum_guarantee
                                              )
                                              _vm.checkCanSubmit(qm)
                                            },
                                          },
                                        },
                                        [_vm._v(" 承認 ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow disabled:bg-very-light-grey font-bold",
                                          attrs: {
                                            disabled:
                                              _vm.quoteMovies[quoteIndex]
                                                .price_minimum_guarantee !==
                                              null,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$modal.show(
                                                "mgPriceModal" + quoteIndex
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 拒否 ")]
                                      ),
                                      _vm.quoteMovies[quoteIndex]
                                        .price_minimum_guarantee !== null
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "px-10 lg:px-6 md:px-4 py-2 ml-3 bg-base-yellow font-bold",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$modal.show(
                                                    "mgPriceModal" + quoteIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 変更 ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "TermsAccordion",
                        {
                          ref: "termsAccordion-" + quoteIndex + "-channel",
                          refInFor: true,
                          attrs: {
                            title:
                              _vm.$t("pages.commons.quotes.customer_request") +
                              _vm.$t("commons.fields.channels"),
                          },
                          on: {
                            updateFilled: function ($event) {
                              return _vm.checkCanSubmit(qm)
                            },
                          },
                        },
                        [
                          _vm.showProviderMessage
                            ? _c("p", { staticClass: "mb-4" }, [
                                _vm._v(
                                  " バイヤーが配信・放送等を希望しているサービスです。提供不能な場合は右側の御社解答欄のチェックを外してください。 "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "TermsItem",
                            {
                              attrs: {
                                showCheckbox: false,
                                showRightSide: _vm.showProviderResponse(qm),
                                isNegotiating: _vm.isNegotiating(qm),
                              },
                            },
                            _vm._l(
                              _vm.quote.channels,
                              function (channel, channelIndex) {
                                return _c("TermsField", {
                                  key: "channel-" + channelIndex,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "original",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.channel",
                                                    [channel.name]
                                                  )
                                                ) +
                                                "("
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: channel.url,
                                                  target: "_blank",
                                                },
                                              },
                                              [_vm._v(_vm._s(channel.url))]
                                            ),
                                            _vm._v(") "),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "negotiate",
                                        fn: function () {
                                          return [
                                            _c(
                                              "FlCheckbox",
                                              {
                                                attrs: {
                                                  trueValue: channel,
                                                  rules: {
                                                    required: {
                                                      allowFalse: false,
                                                    },
                                                  },
                                                  compareValues: ["uuid"],
                                                  immediate: true,
                                                  showError: false,
                                                },
                                                model: {
                                                  value: qm.channels,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      qm,
                                                      "channels",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "qm.channels",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.commons.quotes.allow_distribution_channel",
                                                      [channel.name]
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "result",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.allow_distribution_channel",
                                                    [channel.name]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "TermsAccordion",
                        {
                          ref: "termsAccordion-" + quoteIndex + "-rights",
                          refInFor: true,
                          attrs: {
                            title:
                              _vm.$t("pages.commons.quotes.customer_request") +
                              _vm.$t("pages.commons.quotes.rights"),
                          },
                          on: {
                            updateFilled: function ($event) {
                              return _vm.checkCanSubmit(qm)
                            },
                          },
                        },
                        [
                          _vm.showProviderMessage
                            ? _c("p", { staticClass: "mb-4" }, [
                                _vm._v(
                                  " バイヤーが希望している権利が下記です。提供不能な場合は右側の御社解答欄のチェックを外してください。 "
                                ),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.quote.rights, function (right, rightKey) {
                            return _c(
                              "TermsItem",
                              {
                                key: "right-" + rightKey,
                                attrs: {
                                  showCheckbox: true,
                                  showRightSide: _vm.showProviderResponse(qm),
                                  isNegotiating: _vm.isNegotiating(qm),
                                  value: _vm.rightOfType(right.right_type, qm),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setRightOfType(
                                      right.right_type,
                                      qm,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("TermsField", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "original",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.request_rights",
                                                        [
                                                          _vm.rightsNames[
                                                            right.right_type - 1
                                                          ],
                                                          _vm.textOfEnum(
                                                            _vm.exclusiveTypes,
                                                            right.exclusive_type
                                                          ),
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                right.exclusive_type === 3
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.holdback_right",
                                                              [
                                                                _vm.textOfEnum(
                                                                  _vm.rightHoldbackLength,
                                                                  right.holdback_length
                                                                ),
                                                              ]
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "negotiate",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid grid-cols-3 gap-6",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-span-2" },
                                                  [
                                                    _c("FlRadioGroup", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "pages.consumers.cart.exclusive_type"
                                                        ),
                                                        options:
                                                          _vm.exclusiveTypes,
                                                        showError: false,
                                                        rules: {
                                                          required: true,
                                                          is: right.exclusive_type,
                                                        },
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          $event !== 3
                                                            ? (_vm.rightOfType(
                                                                right.right_type,
                                                                qm
                                                              ).holdback_length =
                                                                null)
                                                            : null
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.rightOfType(
                                                          right.right_type,
                                                          qm
                                                        ).exclusive_type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.rightOfType(
                                                              right.right_type,
                                                              qm
                                                            ),
                                                            "exclusive_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rightOfType(right.right_type, qm).exclusive_type",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-span-1" },
                                                  [
                                                    _c("FlSelect2", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "pages.consumers.cart.holdback"
                                                        ),
                                                        disabled:
                                                          _vm.rightOfType(
                                                            right.right_type,
                                                            qm
                                                          ).exclusive_type !==
                                                          3,
                                                        options:
                                                          _vm.rightHoldbackLength,
                                                        immediate: true,
                                                        showError: false,
                                                        rules: {
                                                          required:
                                                            _vm.rightOfType(
                                                              right.right_type,
                                                              qm
                                                            ).exclusive_type ===
                                                            3,
                                                          is: right.holdback_length,
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.rightOfType(
                                                          right.right_type,
                                                          qm
                                                        ).holdback_length,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.rightOfType(
                                                              right.right_type,
                                                              qm
                                                            ),
                                                            "holdback_length",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rightOfType(right.right_type, qm).holdback_length",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "result",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "col-span-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.allow_rights",
                                                          [
                                                            _vm.rightsNames[
                                                              right.right_type -
                                                                1
                                                            ],
                                                            _vm.textOfEnum(
                                                              _vm.exclusiveTypes,
                                                              _vm.rightOfType(
                                                                right.right_type,
                                                                qm
                                                              ).exclusive_type
                                                            ),
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _vm.rightOfType(
                                                  right.right_type,
                                                  qm
                                                ).exclusive_type === 3
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "col-span-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.allow_holdback_right",
                                                                [
                                                                  _vm.textOfEnum(
                                                                    _vm.rightHoldbackLength,
                                                                    _vm.rightOfType(
                                                                      right.right_type,
                                                                      qm
                                                                    )
                                                                      .holdback_length
                                                                  ),
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                [4, 5, 8, 9].includes(right.right_type)
                                  ? _c("TermsField", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "original",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.right_air_run_count",
                                                        [right.air_run_count]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "negotiate",
                                            fn: function () {
                                              return [
                                                _c("FlInput", {
                                                  attrs: {
                                                    type: "number",
                                                    showError: false,
                                                    rules: {
                                                      required: true,
                                                      is: right.air_run_count,
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.rightOfType(
                                                      right.right_type,
                                                      qm
                                                    ).air_run_count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rightOfType(
                                                          right.right_type,
                                                          qm
                                                        ),
                                                        "air_run_count",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rightOfType(right.right_type, qm).air_run_count",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "result",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.right_air_run_count_allow",
                                                        [
                                                          _vm.rightOfType(
                                                            right.right_type,
                                                            qm
                                                          ).air_run_count,
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "TermsAccordion",
                        {
                          ref: "termsAccordion-" + quoteIndex + "-area",
                          refInFor: true,
                          attrs: {
                            title:
                              _vm.$t("pages.commons.quotes.customer_request") +
                              _vm.$t("pages.commons.quotes.distribution_area"),
                          },
                          on: {
                            updateFilled: function ($event) {
                              return _vm.checkCanSubmit(qm)
                            },
                          },
                        },
                        [
                          _vm.showProviderMessage
                            ? [
                                _vm.quote.all_countries
                                  ? _c("p", { staticClass: "mb-4" }, [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("【全世界配信】")]
                                      ),
                                      _vm._v(
                                        "バイヤーは下記でチェックされている国以外の全世界での配信を希望されています。御社にてさらに配信を除外する国がある場合には、右側の御社回答欄にて配信できない国にチェックを入れてください。例えば、日本を除外する場合は、まずは「アジア」エリアを選択、その後、日本を選んでください。 "
                                      ),
                                    ])
                                  : _c("p", { staticClass: "mb-4" }, [
                                      _vm._v(
                                        " バイヤーが希望する配信エリアが下記です。配信できない国があれば右の御社解答欄からチェックボックスを外してください。 "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          _c(
                            "TermsItem",
                            {
                              attrs: {
                                showRightSide: _vm.showProviderResponse(qm),
                              },
                            },
                            [
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          !_vm.showProviderMessage
                                            ? [
                                                _vm.quote.all_countries
                                                  ? _c(
                                                      "p",
                                                      { staticClass: "mb-4" },
                                                      [
                                                        _vm._v(
                                                          " Requesting worldwide distribution except countries checked below. "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("p", [
                                                      _vm._v(
                                                        "Requesting distribution to countries checked below."
                                                      ),
                                                    ]),
                                              ]
                                            : _vm._e(),
                                          _c("FlAreaCountry", {
                                            attrs: {
                                              value: _vm.quote.countries,
                                              preSelectArea: true,
                                              disabled: true,
                                              rules: {},
                                            },
                                          }),
                                          _vm.showProviderMessage
                                            ? [
                                                _vm.quote.all_countries
                                                  ? [
                                                      _vm.japanInQuoteCountries
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信除外国に含まれています。"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs bg-red-500",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信対象国に含まれています。"
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  : [
                                                      _vm.japanInQuoteCountries
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs bg-red-500",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信対象国に含まれています。"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信対象国に含まれていません。"
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlAreaCountry", {
                                            attrs: {
                                              preSelectArea: true,
                                              disabledCountries:
                                                _vm.quote.countries,
                                              inverseDisabledCountries:
                                                !_vm.quote.all_countries,
                                              immediate: true,
                                              showError: false,
                                              rules: {
                                                required:
                                                  !_vm.quote.all_countries,
                                                length:
                                                  _vm.quote.countries.length,
                                              },
                                              disabled:
                                                qm.status > 2 && !_vm.isEditing,
                                            },
                                            model: {
                                              value: qm.countries,
                                              callback: function ($$v) {
                                                _vm.$set(qm, "countries", $$v)
                                              },
                                              expression: "qm.countries",
                                            },
                                          }),
                                          _vm.showProviderMessage
                                            ? [
                                                _vm.quote.all_countries
                                                  ? [
                                                      _vm.japanInQuoteMovieCountries(
                                                        qm.countries
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信対象国に含まれていません。"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs bg-red-500",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本を配信対象国として許可しています。"
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  : [
                                                      _vm.japanInQuoteMovieCountries(
                                                        qm.countries
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs bg-red-500",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本を配信対象国として許可しています。"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "my-2 p-1 text-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日本は配信対象国に含まれていません。"
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "TermsAccordion",
                        {
                          ref: "termsAccordion-" + quoteIndex + "-schedule",
                          refInFor: true,
                          attrs: {
                            title:
                              _vm.$t("pages.commons.quotes.customer_request") +
                              _vm.$t("pages.commons.quotes.schedule"),
                          },
                          on: {
                            updateFilled: function ($event) {
                              return _vm.checkCanSubmit(qm)
                            },
                          },
                        },
                        [
                          _vm.showProviderMessage
                            ? _c("p", { staticClass: "mb-4" }, [
                                _vm._v(
                                  " バイヤーが希望しているスケジュールが下記です。変更されたい場合は右側の御社解答欄にてカレンダーから日付を選んでください。 "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "TermsItem",
                            {
                              attrs: {
                                showRightSide: _vm.showProviderResponse(qm),
                                isNegotiating: _vm.isNegotiating(qm),
                              },
                            },
                            [
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.consumers.cart.delivery_date"
                                                )
                                              ) +
                                                " : " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.delivery_at,
                                                    false
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlDate2", {
                                            attrs: {
                                              rules: {
                                                dateBefore: {
                                                  baseDate:
                                                    _vm.quote.delivery_at,
                                                  allowSame: true,
                                                },
                                              },
                                              showError: false,
                                            },
                                            model: {
                                              value: qm.delivery_at,
                                              callback: function ($$v) {
                                                _vm.$set(qm, "delivery_at", $$v)
                                              },
                                              expression: "qm.delivery_at",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "result",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.consumers.cart.delivery_date"
                                                )
                                              ) +
                                              " : " +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.delivery_at,
                                                  false
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "sheets.license_agreement.columns.contract_start_at_planned"
                                                )
                                              ) +
                                                " : " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.license_start_at,
                                                    false
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlDate2", {
                                            attrs: {
                                              rules: {
                                                is: _vm.quote.license_start_at,
                                              },
                                              showError: false,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.licenseStartAtChanged(
                                                  qm,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: qm.license_start_at,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  qm,
                                                  "license_start_at",
                                                  $$v
                                                )
                                              },
                                              expression: "qm.license_start_at",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "result",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "sheets.license_agreement.columns.contract_start_at_planned"
                                                )
                                              ) +
                                              " : " +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.license_start_at,
                                                  false
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.commons.quotes.contract_start_delay"
                                                )
                                              ) +
                                                " : " +
                                                _vm._s(
                                                  _vm.textOfEnum(
                                                    _vm.distributionDelay,
                                                    _vm.quote
                                                      .latest_distribution_start_duration
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlSelect2", {
                                            attrs: {
                                              options: _vm.distributionDelay,
                                            },
                                            model: {
                                              value:
                                                qm.latest_distribution_start_duration,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  qm,
                                                  "latest_distribution_start_duration",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "qm.latest_distribution_start_duration",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "result",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.commons.quotes.contract_start_delay"
                                                )
                                              ) +
                                              " : " +
                                              _vm._s(
                                                _vm.textOfEnum(
                                                  _vm.distributionDelay,
                                                  qm.latest_distribution_start_duration
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "sheets.license_agreement.columns.contract_end_at_planned"
                                                )
                                              ) +
                                                " : " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.license_end_at,
                                                    false
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlDate2", {
                                            attrs: {
                                              rules: {
                                                is: _vm.quote.license_end_at,
                                              },
                                              showError: false,
                                            },
                                            model: {
                                              value: qm.license_end_at,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  qm,
                                                  "license_end_at",
                                                  $$v
                                                )
                                              },
                                              expression: "qm.license_end_at",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "result",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "sheets.license_agreement.columns.contract_end_at_planned"
                                                )
                                              ) +
                                              " : " +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.license_end_at,
                                                  false
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("TermsField", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "original",
                                      fn: function () {
                                        return [
                                          _vm.showProviderMessage
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "commons.fields.extimate_effective_until"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.showProviderMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "bg-white rounded p-1 px-2 my-2",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-xs leading-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.quote_date_validity_warning"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "negotiate",
                                      fn: function () {
                                        return [
                                          _c("FlDate2", {
                                            attrs: {
                                              rules: { dateAfter: new Date() },
                                              showError: false,
                                            },
                                            model: {
                                              value: qm.valid_until,
                                              callback: function ($$v) {
                                                _vm.$set(qm, "valid_until", $$v)
                                              },
                                              expression: "qm.valid_until",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "result",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "commons.fields.extimate_effective_until"
                                                )
                                              ) +
                                              " : " +
                                              _vm._s(
                                                _vm.getDateString(
                                                  qm.valid_until,
                                                  false
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.quote.allow_offline_viewing
                        ? _c(
                            "TermsAccordion",
                            {
                              ref: "termsAccordion-" + quoteIndex + "-offline",
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) + _vm.$t("commons.fields.offline_viewing"),
                              },
                              on: {
                                updateFilled: function ($event) {
                                  return _vm.checkCanSubmit(qm)
                                },
                              },
                            },
                            [
                              _vm.showProviderMessage
                                ? _c("p", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      " バイヤーはオフライン視聴を希望しています。拒否したい場合は右側の御社解答欄にてチェックを外してください。 "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "TermsItem",
                                {
                                  attrs: {
                                    showRightSide: _vm.showProviderResponse(qm),
                                    isNegotiating: _vm.isNegotiating(qm),
                                  },
                                },
                                [
                                  _c("TermsField", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "original",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.request_offline_view"
                                                  )
                                                )
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "negotiate",
                                          fn: function () {
                                            return [
                                              _c(
                                                "FlCheckbox",
                                                {
                                                  attrs: {
                                                    rules: {
                                                      required: {
                                                        allowFalse: false,
                                                      },
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      qm.allow_offline_viewing,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        qm,
                                                        "allow_offline_viewing",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qm.allow_offline_viewing",
                                                  },
                                                },
                                                [_vm._v("承認する ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "result",
                                          fn: function () {
                                            return [
                                              qm.allow_offline_viewing == true
                                                ? _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pages.commons.quotes.allow_offline_view"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.deny_offline_view"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm.showProviderMessage
                                ? _c("p", { staticClass: "text-xs" }, [
                                    _vm._v(
                                      " オフライン視聴はスマートフォン等に、映画データを予め保存しておく事で、外出先などで映画を再生できる機能です。 "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.quote && _vm.quote.message
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full p-2 bg-dark-grey rounded mb-2 grid grid-cols-2 gap-2",
                            },
                            [
                              _c("div", { staticClass: "col-span-2" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-bold font-xs" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.quotes.message_delivery"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.translatedMessage &&
                              _vm.translatedMessage !== _vm.quote.message &&
                              _vm.showProviderMessage
                                ? [
                                    _c("span", { staticClass: "col-span-1" }, [
                                      _vm._v(_vm._s(_vm.quote.message)),
                                    ]),
                                    _c("span", { staticClass: "col-span-1" }, [
                                      _vm._v(_vm._s(_vm.translatedMessage)),
                                    ]),
                                  ]
                                : _c("span", { staticClass: "col-span-2" }, [
                                    _vm._v(_vm._s(_vm.quote.message)),
                                  ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._l(_vm.quote.formats, function (format, formatIndex) {
                        return _c(
                          "TermsAccordion",
                          {
                            key: "format-" + formatIndex,
                            ref:
                              "termsAccordion-" +
                              quoteIndex +
                              "-format-" +
                              formatIndex,
                            refInFor: true,
                            attrs: {
                              title: _vm.$t(
                                "commons.fields.movie_format_title",
                                [
                                  _vm.textOfEnum(
                                    _vm.videoType,
                                    format.video_type
                                  ),
                                ]
                              ),
                            },
                            on: {
                              updateFilled: function ($event) {
                                return _vm.checkCanSubmit(qm)
                              },
                            },
                          },
                          [
                            _vm.showProviderMessage
                              ? _c("p", { staticClass: "mb-4" }, [
                                  _vm._v(
                                    " バイヤーが希望されるフォーマットが下記に表示されています。右側の御社解答欄から提供可能なフォーマットを選択してください。 "
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "p",
                              {
                                key: "format-" + formatIndex + "-video",
                                staticClass: "font-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.commons.quotes.audio_visual_format_for",
                                        [
                                          _vm.textOfEnum(
                                            _vm.videoType,
                                            format.video_type
                                          ),
                                        ]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            !format.is_dcp
                              ? _c(
                                  "TermsItem",
                                  {
                                    attrs: {
                                      showCheckbox: true,
                                      showRightSide:
                                        _vm.showProviderResponse(qm),
                                      isNegotiating: _vm.isNegotiating(qm),
                                      value: _vm.videoFormatOfType(
                                        format.video_type,
                                        qm
                                      ),
                                      showWarning:
                                        format.video_type === 1 ||
                                        format.video_type === 2,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setVideoFormatOfType(
                                          format.video_type,
                                          qm,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.videoFormatTemplate,
                                    function (item, fieldKey) {
                                      return _c("TermsField", {
                                        key:
                                          "format-" +
                                          formatIndex +
                                          "-field-" +
                                          fieldKey,
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "original",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.consumerMultipleRequests(
                                                          _vm.$t(item.title),
                                                          item.options,
                                                          format[
                                                            item.key_multiple
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "negotiate",
                                              fn: function () {
                                                return [
                                                  _c("FlRadioGroup", {
                                                    attrs: {
                                                      options:
                                                        _vm.filteredOptions(
                                                          item.options,
                                                          format[
                                                            item.key_multiple
                                                          ]
                                                        ),
                                                      immediate: true,
                                                      rules: {
                                                        required: true,
                                                        oneOf:
                                                          format[
                                                            item.key_multiple
                                                          ],
                                                      },
                                                      showError: false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.videoFormatOfType(
                                                          format.video_type,
                                                          qm
                                                        )[item.key_singular],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.videoFormatOfType(
                                                            format.video_type,
                                                            qm
                                                          ),
                                                          item.key_singular,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "videoFormatOfType(format.video_type, qm)[item.key_singular]",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "result",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.multiple_request_response",
                                                          [
                                                            _vm.textOfEnum(
                                                              item.options,
                                                              _vm.videoFormatOfType(
                                                                format.video_type,
                                                                qm
                                                              )[
                                                                item
                                                                  .key_singular
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "TermsItem",
                                  {
                                    attrs: {
                                      showRightSide:
                                        _vm.showProviderResponse(qm),
                                      isNegotiating: _vm.isNegotiating(qm),
                                    },
                                  },
                                  [
                                    _c("TermsField", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "original",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.commons.quotes.delivery_format"
                                                    )
                                                  )
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "negotiate",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "ValidationProvider",
                                                  {
                                                    attrs: {
                                                      rules: {
                                                        required: {
                                                          allowFalse: false,
                                                        },
                                                      },
                                                      immediate: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "FlCheckbox",
                                                      {
                                                        attrs: {
                                                          value:
                                                            _vm.videoFormatOfType(
                                                              format.video_type,
                                                              qm
                                                            ).is_dcp,
                                                          immediate: true,
                                                          showError: false,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.setVideoFormatOfType(
                                                              format.video_type,
                                                              qm,
                                                              $event
                                                            )
                                                            _vm.videoFormatOfType(
                                                              format.video_type,
                                                              qm
                                                            ).is_dcp = $event
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.multiple_request_response",
                                                                ["DCP"]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "result",
                                            fn: function () {
                                              return [
                                                _vm.videoFormatOfType(
                                                  format.video_type,
                                                  qm
                                                ).is_dcp
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pages.commons.quotes.response_dcp_able"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pages.commons.quotes.response_dcp_unable"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                            _vm._l(
                              _vm.languagesOfTypeInQuote(
                                format.video_type,
                                "subtitle"
                              ),
                              function (subtitle, subKey) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      key:
                                        "format-" +
                                        formatIndex +
                                        "-sub" +
                                        subKey +
                                        "-lang",
                                      staticClass: "font-bold",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.fullLanguageName(subtitle)) +
                                          " " +
                                          _vm._s(
                                            _vm.$t("commons.fields.subtitles")
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "TermsItem",
                                    {
                                      key:
                                        "format-" +
                                        formatIndex +
                                        "-sub" +
                                        subKey +
                                        "-item",
                                      attrs: {
                                        showCheckbox: !(
                                          format.video_type === 1 &&
                                          subtitle.language === "en"
                                        ),
                                        showRightSide:
                                          _vm.showProviderResponse(qm),
                                        isNegotiating: _vm.isNegotiating(qm),
                                        value: _vm.languageFormatOfType(
                                          format.video_type,
                                          subtitle.subtitle_language,
                                          qm
                                        ),
                                        showWarning: format.video_type === 1,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.setLanguageFormatOfType(
                                            qm,
                                            subtitle,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("TermsField", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "original",
                                              fn: function () {
                                                return [
                                                  _vm.showProviderMessage
                                                    ? _c("p", [
                                                        _vm._v(
                                                          "バイヤーは" +
                                                            _vm._s(
                                                              _vm.fullLanguageName(
                                                                subtitle
                                                              )
                                                            ) +
                                                            "字幕を求めています。"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.consumerMultipleRequests(
                                                            _vm.$t(
                                                              "pages.commons.quotes.subtitle_file_format"
                                                            ),
                                                            _vm.subtitleFormatOptions,
                                                            _vm.valuesForSubtitle(
                                                              subtitle
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "negotiate",
                                              fn: function () {
                                                return [
                                                  format.video_type === 1 &&
                                                  format.is_dcp
                                                    ? [
                                                        _c("ValidateParts", {
                                                          attrs: {
                                                            value: true,
                                                            isDisplayErrors: false,
                                                            rules: {
                                                              required: {
                                                                allowFalse: false,
                                                              },
                                                            },
                                                            immediate: true,
                                                          },
                                                        }),
                                                      ]
                                                    : [
                                                        _c("FlRadioGroup", {
                                                          attrs: {
                                                            value:
                                                              _vm.valuesForSubtitle(
                                                                _vm.languageFormatOfType(
                                                                  subtitle.video_type,
                                                                  subtitle.subtitle_language,
                                                                  qm
                                                                )
                                                              ),
                                                            options:
                                                              _vm.filteredOptionsForSubtitle(
                                                                subtitle,
                                                                subtitle.subtitle_file_formats
                                                              ),
                                                            immediate: true,
                                                            rules: {
                                                              required: true,
                                                              oneOf:
                                                                _vm.filteredValuesForSubtitle(
                                                                  subtitle,
                                                                  subtitle.subtitle_file_formats
                                                                ),
                                                            },
                                                            showError: false,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              _vm.setValuesForSubtitle(
                                                                _vm.languageFormatOfType(
                                                                  subtitle.video_type,
                                                                  subtitle.subtitle_language,
                                                                  qm
                                                                ),
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "result",
                                              fn: function () {
                                                return [
                                                  _vm.languageFormatOfType(
                                                    subtitle.video_type,
                                                    subtitle.subtitle_language,
                                                    qm
                                                  ).embedded_subtitle
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.multiple_request_response",
                                                                [
                                                                  _vm.$t(
                                                                    "pages.commons.quotes.baked"
                                                                  ),
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.multiple_request_response",
                                                                [
                                                                  _vm.textOfEnum(
                                                                    _vm.subtitleFormatOptions,
                                                                    _vm.languageFormatOfType(
                                                                      subtitle.video_type,
                                                                      subtitle.subtitle_language,
                                                                      qm
                                                                    )
                                                                      .subtitle_file_format
                                                                  ),
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }
                            ),
                            _vm._l(
                              _vm.languagesOfTypeInQuote(
                                format.video_type,
                                "voiceover"
                              ),
                              function (voiceover, voiceKey) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      key:
                                        "format-" +
                                        formatIndex +
                                        "-voice" +
                                        voiceKey +
                                        "-lang",
                                      staticClass: "font-bold",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fullLanguageName(voiceover)
                                        ) +
                                          _vm._s(
                                            _vm.$t("pages.commons.quotes.dubb")
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "TermsItem",
                                    {
                                      key:
                                        "format-" +
                                        formatIndex +
                                        "-voice" +
                                        voiceKey +
                                        "-item",
                                      attrs: {
                                        showCheckbox: !(
                                          format.video_type === 1 &&
                                          voiceover.language === "en"
                                        ),
                                        showRightSide:
                                          _vm.showProviderResponse(qm),
                                        isNegotiating: _vm.isNegotiating(qm),
                                        value: _vm.languageFormatOfType(
                                          format.video_type,
                                          voiceover.voiceover_language,
                                          qm,
                                          "voiceover"
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.setLanguageFormatOfType(
                                            qm,
                                            voiceover,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("TermsField", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "original",
                                              fn: function () {
                                                return [
                                                  _vm.showProviderMessage
                                                    ? _c("p", [
                                                        _vm._v(
                                                          "バイヤーは" +
                                                            _vm._s(
                                                              _vm.fullLanguageName(
                                                                voiceover
                                                              )
                                                            ) +
                                                            "吹替を求めています。"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "negotiate",
                                              fn: function () {
                                                return undefined
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "result",
                                              fn: function () {
                                                return [
                                                  _vm.languageFormatOfType(
                                                    voiceover.video_type,
                                                    voiceover.subtitle_language,
                                                    qm,
                                                    "voiceover"
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.multiple_request_response",
                                                                [
                                                                  _vm.$t(
                                                                    "pages.commons.quotes.dubb"
                                                                  ),
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      }),
                      _vm._l(
                        _vm.requestedImageTemplate,
                        function (template, index) {
                          return _c(
                            "TermsAccordion",
                            {
                              key: "artwork-" + index,
                              ref:
                                "termsAccordion-" +
                                quoteIndex +
                                "-artwork-" +
                                index,
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) + _vm.$t(template.title),
                              },
                              on: {
                                updateFilled: function ($event) {
                                  return _vm.checkCanSubmit(qm)
                                },
                              },
                            },
                            [
                              _vm.showProviderMessage
                                ? _c("p", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      " バイヤーが希望される" +
                                        _vm._s(_vm.$t(template.title)) +
                                        "のスペックが下記に表示されています。提供可能なフォーマットを右側の御社回答欄にて選択してください。ai等の画像ファイルは、1ファイルでの納品になります。提供ができない場合には、提供可能のチェックを外してください。 "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.showProviderMessage &&
                              template.format === "key_art_formats"
                                ? _c("p", [
                                    _vm._v(
                                      " キーアートのロゴは、英語表記でお願いします "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.dataRequiresImageTemplate(template)
                                ? _c(
                                    "TermsItem",
                                    {
                                      attrs: {
                                        showCheckbox: true,
                                        showRightSide:
                                          _vm.showProviderResponse(qm),
                                        isNegotiating: _vm.isNegotiating(qm),
                                        value: _vm.artworkChAvailability(
                                          qm,
                                          template
                                        ),
                                        showWarning:
                                          template.needs === "screenshot_count",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.setArtworkFromTemplate(
                                            qm,
                                            template,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      template.count
                                        ? _c("TermsField", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "original",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.number_of_images",
                                                              [
                                                                _vm.quote[
                                                                  template.count
                                                                ],
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "negotiate",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grid grid-cols-3 gap-6",
                                                        },
                                                        [
                                                          _c("FlSelect2", {
                                                            staticClass:
                                                              "col-span-1",
                                                            attrs: {
                                                              options:
                                                                _vm.imageCount,
                                                              rules: {
                                                                required: true,
                                                                min_value:
                                                                  _vm.quote[
                                                                    template
                                                                      .count
                                                                  ],
                                                              },
                                                              needsTranslation: false,
                                                              immediate: true,
                                                              showError: false,
                                                            },
                                                            model: {
                                                              value:
                                                                qm[
                                                                  template.count
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    qm,
                                                                    template.count,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "qm[template.count]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "result",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.multiple_request_response",
                                                              [
                                                                qm[
                                                                  template.count
                                                                ] +
                                                                  _vm.$t(
                                                                    "pages.commons.quotes.count"
                                                                  ),
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _c("TermsField", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "original",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.consumerMultipleRequests(
                                                          _vm.$t(
                                                            "commons.fields.image_format"
                                                          ),
                                                          _vm.imageFormat,
                                                          _vm.quote[
                                                            template.formats
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "negotiate",
                                              fn: function () {
                                                return [
                                                  _c("FlRadioGroup", {
                                                    attrs: {
                                                      options:
                                                        _vm.filteredOptions(
                                                          _vm.imageFormat,
                                                          _vm.quote[
                                                            template.formats
                                                          ]
                                                        ),
                                                      rules: {
                                                        required: true,
                                                        oneOf:
                                                          _vm.quote[
                                                            template.formats
                                                          ],
                                                      },
                                                      immediate: true,
                                                      showError: false,
                                                    },
                                                    model: {
                                                      value:
                                                        qm[
                                                          template
                                                            .formatResponse
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          qm,
                                                          template.formatResponse,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "qm[template.formatResponse]",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "result",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.multiple_request_response",
                                                          [
                                                            _vm.textOfEnum(
                                                              _vm.imageFormat,
                                                              qm[
                                                                template
                                                                  .formatResponse
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm.quote[template.size]
                                        ? _c("TermsField", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "original",
                                                  fn: function () {
                                                    return [
                                                      _vm.quote[template.size]
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "sheets.delivery_terms.cell_value.image_size",
                                                                    [
                                                                      _vm.quote[
                                                                        template
                                                                          .size
                                                                      ].width,
                                                                      _vm.quote[
                                                                        template
                                                                          .size
                                                                      ].height,
                                                                    ]
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pages.commons.quotes.cell_value.no_image_size"
                                                                )
                                                              ) + " "
                                                            ),
                                                          ],
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "negotiate",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grid grid-cols-3 gap-6",
                                                        },
                                                        [
                                                          _c("FlInput", {
                                                            staticClass:
                                                              "col-span-1",
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "pages.consumers.cart.width"
                                                              ),
                                                              sublabel: "px",
                                                              rule: {
                                                                required: true,
                                                                min: _vm.quote[
                                                                  template.size
                                                                ].width,
                                                              },
                                                              immediate: true,
                                                              showError: false,
                                                            },
                                                            model: {
                                                              value:
                                                                qm[
                                                                  template.size
                                                                ].width,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    qm[
                                                                      template
                                                                        .size
                                                                    ],
                                                                    "width",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "qm[template.size].width",
                                                            },
                                                          }),
                                                          _c("FlInput", {
                                                            staticClass:
                                                              "col-span-1",
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "pages.consumers.cart.height"
                                                              ),
                                                              sublabel: "px",
                                                              rule: {
                                                                required: true,
                                                                min: _vm.quote[
                                                                  template.size
                                                                ].height,
                                                              },
                                                              immediate: true,
                                                              showError: false,
                                                            },
                                                            model: {
                                                              value:
                                                                qm[
                                                                  template.size
                                                                ].height,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    qm[
                                                                      template
                                                                        .size
                                                                    ],
                                                                    "height",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "qm[template.size].height",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "result",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "sheets.delivery_terms.cell_value.image_size_response",
                                                              [
                                                                qm[
                                                                  template.size
                                                                ].width,
                                                                qm[
                                                                  template.size
                                                                ].height,
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      template.layered
                                        ? _c("TermsField", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "original",
                                                  fn: function () {
                                                    return [
                                                      _vm.quote[
                                                        template.layered
                                                      ]
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pages.commons.quotes.request_layered"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pages.commons.quotes.arrow_unlayered"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "negotiate",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "FlCheckbox",
                                                        {
                                                          attrs: {
                                                            rule: {
                                                              required: {
                                                                allowFalse: false,
                                                              },
                                                            },
                                                            immediate: true,
                                                            showError: false,
                                                          },
                                                          model: {
                                                            value:
                                                              qm[
                                                                template.layered
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                qm,
                                                                template.layered,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "qm[template.layered]",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "レイヤー分けされた画像を提供可能 "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "result",
                                                  fn: function () {
                                                    return [
                                                      qm[template.layered]
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pages.commons.quotes.response_layered"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pages.commons.quotes.response_unlayered"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.quote[template.language]
                                        ? _c("TermsField", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "original",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.request_image_language",
                                                              [
                                                                _vm.textOfEnum(
                                                                  _vm.languages,
                                                                  _vm.quote[
                                                                    template
                                                                      .language
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "negotiate",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "FlCheckbox",
                                                        {
                                                          attrs: {
                                                            rule: {
                                                              required: {
                                                                allowFalse: false,
                                                              },
                                                            },
                                                            trueValue:
                                                              _vm.quote[
                                                                template
                                                                  .language
                                                              ],
                                                            immediate: true,
                                                            showError: false,
                                                          },
                                                          model: {
                                                            value:
                                                              qm[
                                                                template
                                                                  .language
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                qm,
                                                                template.language,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "qm[template.language]",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.textOfEnum(
                                                                _vm.languages,
                                                                _vm.quote[
                                                                  template
                                                                    .language
                                                                ]
                                                              )
                                                            ) + "で提供可能"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "result",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.response_image_language",
                                                              [
                                                                qm[
                                                                  template
                                                                    .language
                                                                ],
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      _vm.quote.needs_font_file
                        ? _c(
                            "TermsAccordion",
                            {
                              ref: "termsAccordion-" + quoteIndex + "-font",
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) + _vm.$t("pages.commons.quotes.font"),
                              },
                              on: {
                                updateFilled: function ($event) {
                                  return _vm.checkCanSubmit(qm)
                                },
                              },
                            },
                            [
                              _vm.showProviderMessage
                                ? _c("p", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      " バイヤーはフォントファイルを希望しています。提供可能なフォーマットを右側の御社回答欄にて選択してください。提供ができない場合には、提供可能のチェックを外してください。 "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "TermsItem",
                                {
                                  attrs: {
                                    showCheckbox: true,
                                    showRightSide: _vm.showProviderResponse(qm),
                                    isNegotiating: _vm.isNegotiating(qm),
                                    value: qm.fonts && qm.fonts.length > 0,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.setFont(qm, $event)
                                    },
                                  },
                                },
                                [
                                  _c("TermsField", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "original",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.request_fontfile"
                                                  )
                                                )
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "negotiate",
                                          fn: function () {
                                            return [
                                              _c("FlRadioGroup", {
                                                attrs: {
                                                  options: _vm.fontFileFormat,
                                                  translateText: false,
                                                  immeditate: true,
                                                  showError: false,
                                                  rules: {
                                                    required: true,
                                                    oneOf: [1, 2, 3, 4, 5, 6],
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.font(qm).movie_font
                                                      .format,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.font(qm).movie_font,
                                                      "format",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "font(qm).movie_font.format",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "result",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.commons.quotes.multiple_request_response",
                                                      [
                                                        _vm.textOfEnum(
                                                          _vm.fontFileFormat,
                                                          _vm.font(qm)
                                                            .movie_font.format
                                                        ),
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm.showProviderMessage
                                ? _c("div", { staticClass: "text-xs" }, [
                                    _c("p", [
                                      _vm._v(
                                        "※フォントファイルは、著作権処理をしたものをUPください。"
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "※フォントファイルがない場合には、VOD側で任意のフォントをつかいます。"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.quote.documents, function (document, index) {
                        return _c(
                          "TermsAccordion",
                          {
                            key: "docuemnt-" + index,
                            ref:
                              "termsAccordion-" +
                              quoteIndex +
                              "-document-" +
                              index,
                            refInFor: true,
                            attrs: {
                              title:
                                _vm.$t(
                                  "pages.commons.quotes.customer_request"
                                ) +
                                _vm.textOfEnum(
                                  _vm.movieDocument,
                                  document.document_type
                                ),
                            },
                            on: {
                              updateFilled: function ($event) {
                                return _vm.checkCanSubmit(qm)
                              },
                            },
                          },
                          [
                            _vm.showProviderMessage
                              ? _c("p", { staticClass: "mb-4" }, [
                                  _vm._v(
                                    " バイヤーは" +
                                      _vm._s(
                                        _vm.textOfEnum(
                                          _vm.movieDocument,
                                          document.document_type
                                        )
                                      ) +
                                      "を希望されています。提供可能なフォーマットを右側の御社回答欄にて選択してください。提供ができない場合には、提供可能のチェックを外してください。 "
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "TermsItem",
                              {
                                attrs: {
                                  showCheckbox: true,
                                  showRightSide: _vm.showProviderResponse(qm),
                                  isNegotiating: _vm.isNegotiating(qm),
                                  value: _vm.documentOfType(
                                    document.document_type,
                                    qm
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setDocumentOfType(
                                      document.document_type,
                                      qm,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("TermsField", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "original",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.consumerMultipleRequests(
                                                    _vm.textOfEnum(
                                                      _vm.movieDocument,
                                                      document.document_type
                                                    ),
                                                    _vm.documentFormat,
                                                    document.formats,
                                                    false
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "negotiate",
                                        fn: function () {
                                          return [
                                            _vm.documentOfType(
                                              document.document_type,
                                              qm
                                            )
                                              ? _c("FlRadioGroup", {
                                                  attrs: {
                                                    options:
                                                      _vm.filteredOptions(
                                                        _vm.documentFormat,
                                                        document.formats
                                                      ),
                                                    rules: {
                                                      required:
                                                        _vm.documentOfType(
                                                          document.document_type,
                                                          qm
                                                        ) !== null,
                                                      oneOf: document.formats,
                                                    },
                                                    immediate: true,
                                                    showError: false,
                                                    translateText: false,
                                                  },
                                                  model: {
                                                    value: _vm.documentOfType(
                                                      document.document_type,
                                                      qm
                                                    ).movie_document.format,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.documentOfType(
                                                          document.document_type,
                                                          qm
                                                        ).movie_document,
                                                        "format",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "documentOfType(document.document_type, qm).movie_document.format",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "result",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.multiple_request_format_response",
                                                    [
                                                      _vm.textOfEnum(
                                                        _vm.documentFormat,
                                                        _vm.documentOfType(
                                                          document.document_type,
                                                          qm
                                                        ).movie_document.format,
                                                        false
                                                      ),
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.quote.social_medias && _vm.quote.social_medias.length
                        ? _c(
                            "TermsAccordion",
                            {
                              ref:
                                "termsAccordion-" +
                                quoteIndex +
                                "-social_media",
                              refInFor: true,
                              attrs: {
                                title:
                                  _vm.$t(
                                    "pages.commons.quotes.customer_request"
                                  ) +
                                  _vm.$t(
                                    "pages.consumers.cart.social_media_links"
                                  ),
                              },
                              on: {
                                updateFilled: function ($event) {
                                  return _vm.checkCanSubmit(qm)
                                },
                              },
                            },
                            [
                              _c(
                                "TermsItem",
                                {
                                  attrs: {
                                    showRightSide: _vm.showProviderResponse(qm),
                                    isNegotiating: _vm.isNegotiating(qm),
                                  },
                                },
                                _vm._l(
                                  _vm.quote.social_medias,
                                  function (media, index) {
                                    return _c("TermsField", {
                                      key: "social-" + index,
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "original",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.textOfEnum(
                                                        _vm.socialMediaTypes,
                                                        media.social_media_type
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "negotiate",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "ValidationProvider",
                                                  {
                                                    attrs: {
                                                      rules: {
                                                        length:
                                                          _vm.quote
                                                            .social_medias
                                                            .length,
                                                        min: 1,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "FlCheckbox",
                                                      {
                                                        attrs: {
                                                          compareValues: [
                                                            "social_media_type",
                                                          ],
                                                          trueValue: {
                                                            social_media_type:
                                                              media.social_media_type,
                                                            account: "",
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            qm.social_medias,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              qm,
                                                              "social_medias",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "qm.social_medias",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.textOfEnum(
                                                                _vm.socialMediaTypes,
                                                                media.social_media_type
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "result",
                                            fn: function () {
                                              return [
                                                _vm.socialMediaInQuoteMovie(
                                                  media.social_media_type,
                                                  qm
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.response_social_media",
                                                              [
                                                                _vm.textOfEnum(
                                                                  _vm.socialMediaTypes,
                                                                  media.social_media_type
                                                                ),
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pages.commons.quotes.response_social_media_unable",
                                                              [
                                                                _vm.textOfEnum(
                                                                  _vm.socialMediaTypes,
                                                                  media.social_media_type
                                                                ),
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "TermsAccordion",
                        {
                          attrs: {
                            title: _vm.$t(
                              "pages.commons.quotes.delivery_method"
                            ),
                          },
                        },
                        [
                          _vm.showProviderMessage
                            ? _c("p", { staticClass: "col-span-7" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pages.commons.quotes.filmination_file_transfer"
                                    )
                                  )
                                ),
                              ])
                            : _c(
                                "TermsItem",
                                [
                                  _c("TermsField", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "original",
                                          fn: function () {
                                            return [
                                              _vm.quote.delivery_method === 1
                                                ? [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Filmination File Transfer (online)"
                                                      ),
                                                    ]),
                                                  ]
                                                : _vm.quote.delivery_method ===
                                                  5
                                                ? [
                                                    _c("p", [
                                                      _vm._v("Google Drive"),
                                                    ]),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "url: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .dropbox_url
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm.quote.delivery_method ===
                                                  2
                                                ? [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Designated File Transfer Server"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "server: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_server_address
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "ftp username: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_username
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "ftp password: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_password
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "ftp filepath: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_filepath
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "ftp trial start: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_trial_start
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "ftp trial end: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .ftp_trial_end
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm.quote.delivery_method ===
                                                    3 ||
                                                  _vm.quote.delivery_method ===
                                                    4
                                                ? [
                                                    _vm.quote
                                                      .delivery_method === 3
                                                      ? _c("p", [
                                                          _vm._v("EMS (HDD)"),
                                                        ])
                                                      : _c("p", [
                                                          _vm._v("FedEx (HDD)"),
                                                        ]),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "receiver full name: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .hdd_fullname
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "street: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .hdd_street
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "city: " +
                                                            _vm._s(
                                                              _vm.quote.hdd_city
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "state: " +
                                                            _vm._s(
                                                              _vm.quote
                                                                .hdd_state
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          "zip: " +
                                                            _vm._s(
                                                              _vm.quote.hdd_zip
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "result",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.users.registration.agree_to_terms"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm.showProviderMessage
            ? _c(
                "div",
                { staticClass: "clear-both mx-auto w-1/2 mb-4 text-center" },
                [
                  _c("p", [
                    _vm._v(
                      " 上記全ての回答を終えると、「この内容で見積書を提出」ボタンが、オレンジ色に変わり 、クリックできるようになります。 "
                    ),
                  ]),
                  _c("p", [
                    _vm._v("このボタンを押すと、見積書の作成は完了です。"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showProviderMessage
            ? _c(
                "div",
                { staticClass: "clear-both mx-auto w-4/12 mb-6" },
                [
                  _vm.quote
                    ? _c(
                        "LinkButton",
                        {
                          attrs: {
                            disabled:
                              (_vm.quote && _vm.finishUpdateQuoteMovie) ||
                              !_vm.isAllFilled,
                            classList: "px-2 py-3 mb-4",
                            processing: _vm.isSending,
                          },
                          on: { clickButton: _vm.beforeUpdateQuoteMovies },
                        },
                        [
                          _vm._v(
                            "この内容で見積もりを" +
                              _vm._s(
                                !_vm.finishUpdateQuoteMovie
                                  ? "提出"
                                  : "提出済み"
                              )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "flex flex-col-reverse md:flex-row flex-wrap-reverse justify-around items-center",
            },
            [
              !_vm.showProviderMessage && _vm.quote && _vm.quote.status < 4
                ? _c(
                    "div",
                    { staticClass: "clear-both mx-auto w-8/12 md:w-4/12" },
                    [
                      _c(
                        "LinkButton",
                        {
                          attrs: {
                            classList:
                              "px-2 py-3 mb-4 bg-light-pink hover:bg-light-pink-lighter",
                            disabled: _vm.quote && _vm.quote.status > 4,
                          },
                          on: {
                            clickButton: function ($event) {
                              return _vm.$modal.show("checkDelete")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.allDeleteTitle) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showProviderMessage && _vm.quote && _vm.quote.status === 3
                ? _c(
                    "div",
                    { staticClass: "clear-both mx-auto w-8/12 md:w-4/12" },
                    [
                      _c(
                        "LinkButton",
                        {
                          attrs: {
                            classList: "px-2 py-3 mb-4",
                            processing: _vm.isPurchasing,
                          },
                          on: { clickButton: _vm.purchaseMovies },
                        },
                        [_vm._v(" PURCHASE ALL ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "beforeSubmit",
          contents:
            "見積書をご提出いただきますと、有効期限内は、変更ができなくなりますので、お間違えのないようお願いいたします。",
          buttons: ["戻って確認する", "提出を続ける"],
        },
        on: { click: _vm.updateQuoteMovies },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "successFailModal",
          contents: _vm.successFailMessage,
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "checkDelete",
          title: _vm.quoteDeleteTitle,
          contents: _vm.quoteDeleteContents,
          buttons: ["取り消し", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.cancelOrDeleteQuote()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }